import React from 'react'

const WestRehydratedNutrients = () => {
  return (
    <div>
      <img
        src='/images/products/west-rehydrated/nutrients/nutrients.jpg'
        alt='nutrients'
        style={{ display: 'block', margin: '0 auto' }}
      />
    </div>
  )
}

export default WestRehydratedNutrients
