import { Button, Grid, Typography } from '@material-ui/core'
import { Product, Option } from 'models'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  product: Product
  selectedOption: Option | undefined
  setSelectedOption: (selectedOption: Option | undefined) => void
}

const ProductOptions = ({
  product,
  selectedOption,
  setSelectedOption
}: Props) => {
  const { t, i18n } = useTranslation()
  const lng = i18n.language
  return (
    <div>
      <Typography variant='h6' className='product-options'>
        {t('Options')}
      </Typography>
      <Grid container spacing={1} className='product-options-info'>
        {product.options &&
          product.options.map((option: any) => {
            return (
              <Grid item xs={4} key={option.id}>
                <Button
                  style={{ textTransform: 'none', padding: '5px 14px' }}
                  key={option.id}
                  color={
                    selectedOption?.id === option.id ? 'primary' : 'inherit'
                  }
                  onClick={() => {
                    setSelectedOption(option)
                  }}
                  variant='outlined'
                  disableRipple
                >
                  {option.onSale && (
                    <img
                      src='/images/products/sale.jpg'
                      alt=''
                      style={{
                        display: 'block',
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        height: '32px'
                      }}
                    />
                  )}
                  <Typography
                    variant='body2'
                    style={{
                      fontWeight: 500,
                      color:
                        selectedOption?.id === option.id ? 'primary' : 'inherit'
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        lng === 'en' ? `${option.label}` : `${option.labelZh}`
                    }}
                  ></Typography>
                </Button>
              </Grid>
            )
          })}
      </Grid>
    </div>
  )
}

export default ProductOptions
