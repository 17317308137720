import { Domains, PromotionWays } from 'constant'
import { Coupon } from 'models'
import { applyCoupon } from 'api'

export const getPromote = async (
  code: string,
  setError,
  setOrder,
  order,
  setDiscountPrice,
  setIsApplied
) => {
  const res = await applyCoupon(
    code.toUpperCase(),
    process.env.REACT_APP_DOMAIN_NAME as Domains
  )
  if (res.data.status === 'success') {
    const coupon: Coupon = res.data.data
    const today = new Date()
    const couponExpireTime = new Date(coupon.expireTime)
    if (coupon.numberOfUse > 0 && today < couponExpireTime) {
      setOrder({
        ...order,
        coupon: coupon
      })
      switch (coupon.promotion) {
        case PromotionWays.DISCOUNT:
          setDiscountPrice(
            (order.subtotal as number) *
              ((100 - (coupon.discount as number)) / 100)
          )
          break
        case PromotionWays.FIXED_PRICE:
          setDiscountPrice(coupon.fixedPrice as number)
          break
        default:
          setDiscountPrice(0)
      }
      setIsApplied(true)
      return true
    } else {
      setError(true)
      return false
    }
  } else {
    setError(true)
    return false
  }
}
