export const galleryImages = [
  {
    src: '/images/eastcoast/gallery1.jpg',
    alt: 'Sea Cucumber Products'
  },
  { src: '/images/eastcoast/gallery2.jpg', alt: 'Sea cucumber real shot' },
  { src: '/images/eastcoast/gallery3.jpg', alt: 'Sea Cucumber Sashimi' },
  {
    src: '/images/eastcoast/gallery4.jpg',
    alt: 'Cold sea cucumber platter'
  },
  { src: '/images/eastcoast/gallery5.jpg', alt: 'sea cucumber recipes' },
  {
    src: '/images/eastcoast/gallery6.jpg',
    alt: 'sea cucumber inside out'
  }
]

export const processImages = [
  {
    src: '/images/eastcoast/process-fishing.jpg',
    alt: 'urban fishing in Halifax',
    description: 'Fishing'
  },
  {
    src: '/images/eastcoast/process-transporting.jpg',
    alt: 'Sea cucumber transportation',
    description: 'Transporting'
  },
  {
    src: '/images/eastcoast/process-cleaning.jpg',
    alt: 'Sea cucumber factory',
    description: 'Transporting'
  },
  {
    src: '/images/eastcoast/process-cooking.jpg',
    alt: 'High temperature sterilized sea cucumber',
    description: 'Cooking'
  },
  {
    src: '/images/eastcoast/process-drying.jpg',
    alt: 'Sea cucumber drying process',
    description: 'Drying'
  },
  {
    src: '/images/eastcoast/process-packaging.jpg',
    alt: 'High technology for sea cucumber packaging',
    description: 'Packaging'
  }
]
