export const BlogData = {
  ChineseFood: {
    id: '90083d59-2e6f-4bb3-9dcd-cfa6b38ccf29',
    name: 'Food-Recipes',
    imgSrc: '/images/blog/traditionalcf-img.jpg',
    imgAlt: 'sea cucumber recipes',
    imgAltZh: '海参食谱',
    date: 'January 30, 2020',
    blogPath: '/blog/Food-Recipes',
    title: 'Traditional Chinese Food Recipes: Nourish Your Vital Substances',
    shortContent:
      'Chinese FoodFood Recipes Sea Cucumbers are nutrient-rich marine animals from the Holothuroidea class of the Echinoderm family, which includes of...'
  },
  SeaCucumber: {
    id: 'fa9133d0-d927-4a48-a277-2f90742f2ab6',
    name: 'Health-Benefits',
    imgSrc: '/images/blog/seacucumber-img.jpg',
    imgAlt: 'Fresh Alaska Sea Cucumber',
    imgAltZh: '鲜活阿拉斯加红刺参',
    date: 'January 30, 2020',
    blogPath: '/blog/Health-Benefits',
    title: 'Sea Cucumber: An Unusual Food With Health Benefits',
    shortContent:
      'UsesNutrition Facts While you may not be familiar with sea cucumbers, they’re considered a delicacy in many Asian cultures.Not to...'
  },
  NutritiveValue: {
    id: '10dbd759-fe43-4427-be0a-959f02e00981',
    name: 'Nutrition-Value',
    imgSrc: '/images/blog/nutritivevalue-img.jpg',
    imgAlt: 'Nutritional value of sea cucumber',
    imgAltZh: '海参的营养价值',
    date: 'September 12, 2018',
    blogPath: '/blog/Nutrition-Value',
    title: 'Nutritive Value',
    shortContent:
      'In 2010, during the Annual Meeting & Food Expo (Chicago, USA, 2010.07), scientists pointed out that the tissue of Canadian sea cucumber shows...'
  },
  RehydrateMethod: {
    id: 'ad202672-6121-44be-a332-e6e36c52ac5d',
    name: 'Rehydrate',
    imgSrc: '/images/blog/rehydrate-img.jpg',
    imgAlt: 'raw sea cucumber dish',
    imgAltZh: '生吃海参',
    date: 'September 12, 2018',
    blogPath: '/blog/Rehydrate',
    title: 'Rehydrate Method',
    shortContent:
      'Atlantic sea cucumbers are harvested from the eastern coast of Canada and grow on the sea floor about 30 meters...'
  },
  SeaCucumber_zh: {
    id: '472c7b37-4879-4a2d-892c-bdc9d02f22dc',
    imgSrc: '/images/blog/seacucumber-img.jpg',
    imgAlt: 'raw sea cucumber dish',
    imgAltZh: '生吃海参',
    date: '二月 25, 2020',
    blogPath: '/blog/472c7b37-4879-4a2d-892c-bdc9d02f22dc',
    title: '海参：特殊的保健食品',
    shortContent:
      '虽然您可能不熟悉海参，但海参在许多亚洲文化中都被认为是美味佳肴。与蔬菜不同， 海参是海洋动物。虽然它们的身影遍布世界，但最大的种群生活在太平洋中......'
  },
  ThesePeople: {
    id: '323af45d-ee5f-41e0-9547-c25a1dc81e6d',
    imgSrc: '/images/blog/blog-zh2-img.jpg',
    imgAlt: 'Nutritional value of sea cucumber',
    imgAltZh: '海参的营养价值',
    date: '十月 2, 2018',
    blogPath: '/blog/323af45d-ee5f-41e0-9547-c25a1dc81e6d',
    title: '[科普]这些长寿到不可思议年纪的人，带你解密海参进补的神奇之谜',
    shortContent:
      '海参，六亿年的生物，地球上最古老生物之一。日本向岛，当今世界已知最著名的长寿之乡，而当地人长寿的秘诀，就是因为他们长期食用海参进补......'
  },
  MostIn: {
    id: '8716ae9f-e22e-4375-9699-ce385e5b8c62',
    imgSrc: '/images/blog/blog-zh3-img.jpg',
    imgAlt: 'fisherman boats',
    imgAltZh: '渔民渔船',
    date: '十月 2, 2018',
    blogPath: '/blog/8716ae9f-e22e-4375-9699-ce385e5b8c62',
    title: '[科普]最in干货，手把手教你，如何选购优质北极参！',
    shortContent: '1 常规辨别北极参的优劣——需要：“一看”、“二尝”、“三闻”......'
  },

  CanadaSeaCucumber: {
    id: '6096fb08-4161-4832-b2b9-ba59fef1cc3e',
    imgSrc: '/images/blog/blog-zh4-img.jpg',
    imgAlt: 'sea cucumber underwater world',
    imgAltZh: '海参水域',
    date: '十月 2, 2018',
    blogPath: '/blog/6096fb08-4161-4832-b2b9-ba59fef1cc3e',
    title:
      '[实用]市面上那么多海参自称是加拿大出产，真正来自于加拿大的海参是哪几个品种呢?',
    shortContent: '1 从图和外观上去对比......'
  },
  CanadaVsChina: {
    id: '3064c4ed-0f73-4de0-871e-0b3dee2b8708',
    imgSrc: '/images/blog/blog-zh5-img.jpg',
    imgAlt: 'Slicing of sea cucumbers',
    imgAltZh: '海参横截面',
    date: '九月 11, 2018',
    blogPath: '/blog/3064c4ed-0f73-4de0-871e-0b3dee2b8708',
    title: '加拿大野生海参VS中国养殖海参',
    shortContent:
      '随着性价比极高的加拿大野生海参逐渐走入大家的视野，消费者们选择海参的空间变得更加广阔了。最近有很多人向小编反应他们在国产海参和进口海参之间不知道如何选择和区分优劣...'
  },
  CanadaArctic: {
    id: '62b0c9f2-4f3c-407d-97a5-1110c306f7df',
    imgSrc: '/images/blog/blog-zh6-img.jpg',
    imgAlt: 'how do sea cucumbers move',
    imgAltZh: '海参如何移动',
    date: '九月 11, 2018',
    blogPath: '/blog/62b0c9f2-4f3c-407d-97a5-1110c306f7df',
    title: '加拿大北极参知识小百科',
    shortContent:
      '东海岸北极海参(学名：Cucumaria frondosa)产于加拿大东部沿海，通常分布在新斯科舍至纽芬兰周边水域底层。他们的性别比例是1：1，在基岩繁衍。海参用管形足附着在底部， 且很少移动。它们是滤食性动物，通过过滤水体来获取食物......'
  },
  NutritiveValue_zh: {
    id: 'd58d16ae-3b97-4de9-b16d-7114f72edf63',
    imgSrc: '/images/blog/nutritivevalue-img.jpg',
    imgAlt: 'Nutritional value of sea cucumber',
    imgAltZh: '海参的营养价值',
    date: '九月 12, 2018',
    blogPath: '/blog/d58d16ae-3b97-4de9-b16d-7114f72edf63',
    title: '营养价值',
    shortContent:
      '2010年，在年会暨食品博览会期间（芝加哥，美国，2010年7月），科学家指出，加拿大海参 的组织具有ACE活性，对自由基的吸收能力极强......'
  },
  YouTubeRecommendBlog: {
    id: 'e4f0c918-957c-44b1-851d-bb7757cff590',
    imgSrc: '/images/blog/youtuber-cover.png',
    date: '八月 01, 2021',
    blogPath: '/blog/e4f0c918-957c-44b1-851d-bb7757cff590',
    title: '知名YouTuber油管博主的好物推荐 – 在加拿大最值得买的特产',
    shortContent:
      '居住在加拿大的小明博主来分享好物啦！~ 这期是物美质优的SEACOO加拿大北极参。'
  }
}

export const ProductData = {
  bechePowder: {
    id: '7f87ea9b-c652-40ad-82f4-17db00ce3542',
    pathName: 'Bêche-Sea-Cucumber-Supplement',
    properties: 'Sea Cucumber ｜ 100% Natural ｜ Supplement',
    propertiesZh: '浓缩胶囊 ｜ 纯天然原料 ｜ 海参补充剂'
  },
  driedWestCoastBag: {
    id: '2959ade1-e357-44a6-9d40-4dd1d58da24e',
    pathName: 'Dried-West-Coast-Sea-Cucumber-Bag',
    properties: 'WestCoast ｜ Sea Cucumber ｜Wild-Caught',
    propertiesZh: '西海岸红刺参 ｜ 野生海参 ｜ 天然抗氧化食物'
  },
  driedEastCoastBag: {
    id: '303517f2-aea8-4ee4-b7eb-b22dd16ddef9',
    pathName: 'Dried-East-Coast-Sea-Cucumber-Whole-Bag',
    properties: 'EastCoast ｜ Sea Cucumber ｜Wild-Caught',
    propertiesZh: '东海岸北极参 ｜ 野生海参 ｜ 天然抗氧化食物'
  },
  driedEastCoastBox: {
    id: '5ad51049-fa32-43c1-99d9-f678e17125fb',
    pathName: 'Dried-East-Coast-Sea-Cucumber-Whole-Box',
    properties: 'EastCoast ｜ Sea Cucumber ｜Wild-Caught',
    propertiesZh: '东海岸北极参 ｜ 野生海参 ｜ 天然抗氧化食物'
  },
  driedWestCoastBox: {
    id: '649c5ac5-e4d7-4e3a-875e-47ffd59990cd',
    pathName: 'Dried-West-Coast-Sea-Cucumber-Box',
    properties: 'WestCoast ｜ Sea Cucumber ｜Wild-Caught',
    propertiesZh: '西海岸红刺参 ｜ 野生海参 ｜ 天然抗氧化食物'
  },
  driedEastCoastSplitBag: {
    id: '6f16e1b5-35f8-43e0-a9a5-627f25fb9a36',
    pathName: 'Dried-East-Coast-Sea-Cucumber-Split-Bag',
    properties: 'EastCoast ｜ Sea Cucumber ｜Wild-Caught',
    propertiesZh: '东海岸北极参 ｜ 野生海参 ｜ 天然抗氧化食物'
  },
  driedEastCoastSplitBox: {
    id: 'dc0c6198-797d-4ad2-a626-2410494b2e40',
    pathName: 'Dried-East-Coast-Sea-Cucumber-Split-Box',
    properties: 'EastCoast ｜ Sea Cucumber ｜Wild-Caught',
    propertiesZh: '东海岸北极参 ｜ 野生海参 ｜ 天然抗氧化食物'
  },
  becheCollagen: {
    id: 'a403296b-7c17-47d4-94a8-44bfebb954cd',
    pathName: 'Bêche-Sea-Cucumber-Supplement-Collagen-Women',
    properties: 'Collagen ｜ Made in Japan ｜ Supplement',
    propertiesZh: '胶原蛋白 ｜ 日本制造 ｜ 海参补充剂'
  },
  chowder: {
    id: 'f0afb46b-4077-4705-bb35-5e821b54566c',
    pathName: 'Sea-Cucumber-Fish-Maw-Chowder'
  },
  driedEastCoastGiftBox: {
    id: '70faa11a-cafe-4346-b7dd-a25806d0c732',
    pathName: 'Dried-East-Coast-Sea-Cucumber-Whole-Gift-Box',
    properties: 'EastCoast ｜ Sea Cucumber ｜Wild-Caught',
    propertiesZh: '东海岸北极参 ｜ 野生海参 ｜ 天然抗氧化食物'
  },
  driedWestCoastGiftBox: {
    id: '649c5ac5-e4d7-4e3a-875e-47ffd59990cd',
    pathName: 'Dried-West-Coast-Sea-Cucumber-Gift-Box',
    properties: 'WestCoast ｜ Sea Cucumber ｜Wild-Caught',
    propertiesZh: '西海岸红刺参 ｜ 野生海参 ｜ 天然抗氧化食物'
  },
  rehydratedRedBag: {
    id: '820bb537-9210-41a6-8890-c858e37c5d3d',
    pathName: 'Rehydrated-East-Coast-Sea-Cucumber-Bag'
  },
  rehydratedBlackBox: {
    id: 'b065455f-ace0-458b-b498-764e54cf6f85',
    pathName: 'Rehydrated-West-Coast-Sea-Cucumber-Box'
  },
  choyDumpling: {
    id: '122f77f8-50c3-456a-8ed6-c7e32b4e2832',
    pathName: 'Sea-Cucumber-Pork-Bok-Choy-Dumpling'
  },
  leekDumpling: {
    id: '69639e5c-ec35-4c46-8201-76d20a177bf3',
    pathName: 'Sea-Cucumber-Pork-Leek-Dumpling'
  }
}

export const PaymentMethodLabel = {
  VISA: 'credit card',
  PAYPAL: 'PayPal',
  ALIPAY: 'AliPay',
  WECHATPAY: 'WeChatPay'
}

export const PromotionWays = {
  DISCOUNT: 'discount',
  FIXED_PRICE: 'fixed'
}

export const CanadaProvinces = [
  { name: 'Alberta', abbreviation: 'AB' },
  { name: 'British Columbia', abbreviation: 'BC' },
  { name: 'Manitoba', abbreviation: 'MB' },
  { name: 'New Brunswick', abbreviation: 'NB' },
  { name: 'Newfoundland and Labrador', abbreviation: 'NL' },
  { name: 'Northwest Territories', abbreviation: 'NT' },
  { name: 'Nova Scotia', abbreviation: 'NS' },
  { name: 'Nunavut', abbreviation: 'NU' },
  { name: 'Ontario', abbreviation: 'ON' },
  { name: 'Prince Edward Island', abbreviation: 'PE' },
  { name: 'Quebec', abbreviation: 'QC' },
  { name: 'Saskatchewan', abbreviation: 'SK' },
  { name: 'Yukon Territory', abbreviation: 'YT' }
]

export enum PaymentType {
  WeChat = 'WECHATPAY',
  Stripe = 'STRIPE',
  PayPal = 'PAYPAL',
  Ali = 'ALIPAY'
}
export const USAStates = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RL' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
  { name: 'Washington District of Columbia', abbreviation: 'WDC' }
]

export const ChinaProvinces = [
  { name: '安徽', abbreviation: 'AH' },
  { name: '北京', abbreviation: 'BJ' },
  { name: '福建', abbreviation: 'FJ' },
  { name: '甘肃', abbreviation: 'GS' },
  { name: '广东', abbreviation: 'GD' },
  { name: '广西', abbreviation: 'GX' },
  { name: '贵州', abbreviation: 'GZ' },
  { name: '海南', abbreviation: 'HI' },
  { name: '河北', abbreviation: 'HE' },
  { name: '河南', abbreviation: 'HA' },
  { name: '黑龙江', abbreviation: 'HL' },
  { name: '湖北', abbreviation: 'HB' },
  { name: '湖南', abbreviation: 'HN' },
  { name: '吉林', abbreviation: 'JL' },
  { name: '江苏', abbreviation: 'JS' },
  { name: '江西', abbreviation: 'JX' },
  { name: '辽宁', abbreviation: 'LN' },
  { name: '内蒙古自治区', abbreviation: 'NM' },
  { name: '宁夏', abbreviation: 'NX' },
  { name: '青海', abbreviation: 'QH' },
  { name: '山东', abbreviation: 'SD' },
  { name: '山西', abbreviation: 'SX' },
  { name: '陕西', abbreviation: 'SN' },
  { name: '上海', abbreviation: 'SH' },
  { name: '四川', abbreviation: 'SC' },
  { name: '天津', abbreviation: 'TJ' },
  { name: '西藏', abbreviation: 'XZ' },
  { name: '新疆', abbreviation: 'XJ' },
  { name: '云南', abbreviation: 'YN' },
  { name: '浙江', abbreviation: 'ZJ' },
  { name: '重庆', abbreviation: 'CQ' }
]

export enum Domains {
  seacoo = 'seacoo',
  seacooCN = 'seacoo-cn',
  beche = 'beche'
}
