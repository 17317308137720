import React from 'react'

const ChoyDumplingNutrients = () => {
  return (
    <div>
      <img
        src='/images/products/dumpling/nutrients/pak-choy-dumpling-nutrients.jpg'
        alt='pak-choy-dumpling-nutrients'
        style={{ display: 'block', margin: '0 auto' }}
      />
    </div>
  )
}

export default ChoyDumplingNutrients
