import { BlogData } from 'constant'

export const blogsEn = [
  {
    path: BlogData.NutritiveValue.blogPath,
    image: BlogData.NutritiveValue.imgSrc,
    alt: 'sea cucumber nutritive value',
    title: BlogData.NutritiveValue.title,
    shortContent: BlogData.NutritiveValue.shortContent
  },
  {
    path: BlogData.RehydrateMethod.blogPath,
    image: BlogData.RehydrateMethod.imgSrc,
    alt: 'Sea Cucumber cut',
    title: BlogData.RehydrateMethod.title,
    shortContent: BlogData.RehydrateMethod.shortContent
  },
  {
    path: BlogData.ChineseFood.blogPath,
    image: BlogData.ChineseFood.imgSrc,
    alt: 'chinese sea cucumber recipe',
    title: BlogData.ChineseFood.title,
    shortContent: BlogData.ChineseFood.shortContent
  }
]

export const blogsZh = [
  {
    path: BlogData.ThesePeople.blogPath,
    image: BlogData.ThesePeople.imgSrc,
    alt: '',
    title: BlogData.ThesePeople.title,
    shortContent: BlogData.ThesePeople.shortContent
  },
  {
    path: BlogData.CanadaSeaCucumber.blogPath,
    image: BlogData.CanadaSeaCucumber.imgSrc,
    alt: '',
    title: BlogData.CanadaSeaCucumber.title,
    shortContent: BlogData.CanadaSeaCucumber.shortContent
  },
  {
    path: BlogData.MostIn.blogPath,
    image: BlogData.MostIn.imgSrc,
    alt: '',
    title: BlogData.MostIn.title,
    shortContent: BlogData.MostIn.shortContent
  }
]
