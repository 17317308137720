export const galleryImages = [
  {
    src: '/images/westcoast/gallery1.jpg',
    alt: 'Fish factor Alaska Red Sea Cucumber'
  },
  { src: '/images/westcoast/gallery2.jpg', alt: 'Sea Cucumber Taste Reviews' },
  { src: '/images/westcoast/gallery3.jpg', alt: 'Sea Cucumber Cooking Guides' },
  {
    src: '/images/westcoast/gallery4.jpg',
    alt: 'difference between various sea cucumbers'
  },
  { src: '/images/westcoast/gallery5.jpg', alt: 'sea cucumber tendon' },
  {
    src: '/images/westcoast/gallery6.jpg',
    alt: 'giant Alaska Red Sea Cucumber'
  }
]

export const processImages = [
  {
    src: '/images/westcoast/process-fishing.jpg',
    alt: 'Vancouver Scuba diving',
    description: 'Fishing'
  },
  {
    src: '/images/westcoast/process-transporting.jpg',
    alt: 'Transport of live sea cucumber',
    description: 'Transporting'
  },
  {
    src: '/images/westcoast/process-cleaning.jpg',
    alt: 'fresh Alaska Red Sea Cucumber',
    description: 'Transporting'
  },
  {
    src: '/images/westcoast/process-cooking.jpg',
    alt: 'processing Alaska Red Sea Cucumber',
    description: 'Cooking'
  },
  {
    src: '/images/westcoast/process-drying.jpg',
    alt: 'best Alaska Red Sea Cucumber',
    description: 'Drying'
  },
  {
    src: '/images/westcoast/process-packaging.jpg',
    alt: 'wild Alaska Red Sea Cucumber',
    description: 'Packaging'
  }
]
