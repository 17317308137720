export const SlideImages = {
  imagesEnEvent: [
    {
      url: '/product/#',
      src: '/images/canada-day/carousel-en.jpg',
      alt: 'Canada Day Event'
    },
    {
      url: '/east-coast/#',
      src: '/images/home/home-slide2.jpg',
      alt: 'east coast atlantic sea cucumber'
    },
    {
      url: '/west-coast/#',
      src: '/images/home/home-slide3.jpg',
      alt: 'west coast alaska sea cucumber'
    },
    {
      url: '/product/Bêche-Sea-Cucumber-Supplement/#',
      src: '/images/home/home-slide4.jpg',
      alt: 'Beche sea cucumber supplement'
    }
  ],
  imagesZhEvent: [
    {
      url: '/product/#',
      src: '/images/canada-day/carousel-zh.jpg',
      alt: '加拿大国庆日活动'
    },
    {
      url: '/east-coast/#',
      src: '/images/home/home-slide2-zh.jpg',
      alt: '加拿大东海岸北极参'
    },
    {
      url: '/west-coast/#',
      src: '/images/home/home-slide3-zh.jpg',
      alt: '加拿大西海岸阿拉斯加红刺参'
    },
    {
      url: '/product/Bêche-Sea-Cucumber-Supplement/#',
      src: '/images/home/home-slide4-zh.png',
      alt: 'Beche海参补充剂'
    }
  ],
  imagesEn: [
    {
      url: '/product/#',
      src: '/images/home/slide-video.mp4',
      alt: 'SEACOO优质海参',
      attribute: 'video'
    },
    {
      url: '/product/#',
      src: '/images/home/home-slide1.png',
      alt: 'canada quality sea cucumber',
      attribute: 'image'
    },
    {
      url: '/east-coast/#',
      src: '/images/home/home-slide2.jpg',
      alt: 'east coast atlantic sea cucumber',
      attribute: 'image'
    },
    {
      url: '/west-coast/#',
      src: '/images/home/home-slide3.jpg',
      alt: 'west coast alaska sea cucumber',
      attribute: 'image'
    },
    {
      url: '/product/Bêche-Sea-Cucumber-Supplement/#',
      src: '/images/home/home-slide4.jpg',
      alt: 'Beche sea cucumber supplement',
      attribute: 'image'
    }
  ],
  imagesZh: [
    {
      url: '/product/#',
      src: '/images/home/slide-video.mp4',
      alt: 'SEACOO优质海参',
      attribute: 'video'
    },
    {
      url: '/product/#',
      src: '/images/home/home-slide1-zh.png',
      alt: 'SEACOO优质海参',
      attribute: 'image'
    },
    {
      url: '/east-coast/#',
      src: '/images/home/home-slide2-zh.jpg',
      alt: '加拿大东海岸北极参',
      attribute: 'image'
    },
    {
      url: '/west-coast/#',
      src: '/images/home/home-slide3-zh.jpg',
      alt: '加拿大西海岸阿拉斯加红刺参',
      attribute: 'image'
    },
    {
      url: '/product/Bêche-Sea-Cucumber-Supplement/#',
      src: '/images/home/home-slide4-zh.png',
      alt: 'Beche海参补充剂',
      attribute: 'image'
    }
  ],
  imagesZhCN: [
    {
      url: '/product/#',
      src: '/images/home/slide-video.mp4',
      alt: 'SEACOO优质海参',
      attribute: 'video'
    },
    {
      url: '/product/#',
      src: '/images/home/home-slide1-zh.png',
      alt: 'SEACOO优质海参',
      attribute: 'image'
    },
    {
      url: '/east-coast/#',
      src: '/images/home/home-slide2-zh-cn.jpg',
      alt: '加拿大东海岸北极参',
      attribute: 'image'
    },
    {
      url: '/west-coast/#',
      src: '/images/home/home-slide3-zh-cn.jpg',
      alt: '加拿大西海岸阿拉斯加红刺参',
      attribute: 'image'
    },
    {
      url: '/product/Sea-Cucumber-Fish-Maw-Chowder/#',
      src: '/images/home/home-slide4-zh-cn.jpg',
      alt: '海参鱼胶佛跳墙',
      attribute: 'image'
    },
    {
      url: '/product/Bêche-Sea-Cucumber-Supplement-Collagen-Women/#',
      src: '/images/home/home-slide5-zh.jpg',
      alt: 'Beche海参胶原蛋白粉',
      attribute: 'image'
    }
  ]
}
