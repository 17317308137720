export const becheDetailImages = [
  {
    src: '/images/products/beche/product-detail-img1.jpg',
    alt: 'product-detail-img1',
    title: 'Wild Caught, Sustainably Sourced',
    details:
      'This powerful addition to your healthy routine supports marine conservation and is aligned with Canada’s strict ocean protection standards.'
  },
  {
    src: '/images/products/beche/product-detail-img2.jpg',
    alt: 'product-detail-img2',
    title: 'From Clean and Cold Canadian Waters',
    details:
      'Trust the benefits of Canadian Sea Cucumber carefully harvested from the clean, cold waters by family fisheries off the coast of Nova Scotia.'
  },
  {
    src: '/images/products/beche/product-detail-img3.jpg',
    alt: 'product-detail-img3',
    title: '100% Encapsulated Sea Cucumber',
    details:
      'With absolutely no fillers, artificial ingredients, preservatives or sweeteners, you get the full benefit of 100% encapsulated Canadian Sea Cucumber.'
  },
  {
    src: '/images/products/beche/product-detail-img4.jpg',
    alt: 'product-detail-img4',
    title: 'Immune System Support',
    details:
      'Get deep immune support that enhances your body’s natural defences with support for the most critical cells in your immune system.'
  },
  {
    src: '/images/products/beche/product-detail-img5.jpg',
    alt: 'product-detail-img5',
    title: 'Antioxidants for Good Health',
    details:
      'Get you daily boost of antioxidants protecting your cells from oxidative damage caused by free radicals with a uniquely concentrated, encapsulated powder.'
  },
  {
    src: '/images/products/beche/product-detail-img6.jpg',
    alt: 'product-detail-img6',
    title: 'An Important Source of Amino Acids',
    details:
      'Support the critical function of amino acids required for building proteins and synthesis of hormones and neurotransmitters.'
  }
]
