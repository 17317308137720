import { BlogData } from 'constant'

export const blogsZhCN = [
  {
    path: BlogData.ThesePeople.blogPath,
    image: BlogData.ThesePeople.imgSrc,
    alt: '',
    title: BlogData.ThesePeople.title,
    shortContent: BlogData.ThesePeople.shortContent
  },
  {
    path: BlogData.CanadaSeaCucumber.blogPath,
    image: BlogData.CanadaSeaCucumber.imgSrc,
    alt: '',
    title: BlogData.CanadaSeaCucumber.title,
    shortContent: BlogData.CanadaSeaCucumber.shortContent
  },
  {
    path: BlogData.MostIn.blogPath,
    image: BlogData.MostIn.imgSrc,
    alt: '',
    title: BlogData.MostIn.title,
    shortContent: BlogData.MostIn.shortContent
  }
]
