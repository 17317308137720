import React, { useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useStyles } from './style'

const AnnouncementBar = () => {
  const classes = useStyles()
  const [showBar, setShowBar] = useState(true)
  const { t } = useTranslation()
  return (
    <div className={showBar ? classes.root : classes.unShow}>
      <Close
        className='close-icon'
        onClick={() => {
          setShowBar(false)
        }}
      />
      <Carousel
        showArrows={false}
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        infiniteLoop={true}
        autoPlay={true}
        emulateTouch={true}
        interval={6000}
      >
        <div>
          <Typography
            variant='body2'
            dangerouslySetInnerHTML={{
              __html: t(
                "<span class='free-shipping'>Free shipping</span> on orders over $150 CAD (tax exclude)&nbsp; or over 3 lbs"
              )
            }}
          />
        </div>
        <div>
          <Typography
            variant='body2'
            dangerouslySetInnerHTML={{
              __html: t('Welcome to Seacoo!')
            }}
          />
        </div>
      </Carousel>
    </div>
  )
}

export default AnnouncementBar
